import React from "react";
import styled from "styled-components";
import PriceTable from "./PriceTable";
export const closeButton = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="2.057"
      height="20.57"
      transform="matrix(0.707099 -0.707115 0.707099 0.707115 0.0849609 1.88086)"
      fill="#FF5576"
    />
    <rect
      width="2.057"
      height="20.57"
      transform="matrix(0.707099 0.707115 -0.707099 0.707115 14.6299 0.425781)"
      fill="#FF5576"
    />
  </svg>
);
export default function PriceModal({ onClose, comboArr }) {
  return (
    <SPriceModal>
      <div className="close-button" onClick={onClose}>
        {closeButton}
      </div>
      <h2>Quy đổi điểm bình chọn</h2>
      <div className="price-container">
        <PriceTable comboArr={comboArr} />
      </div>
      {/* <div className="note-text nowrap">
        Combo vote sẽ được{" "}
        <span style={{ fontSize: "17px" }}>Nhân đôi số điểm</span>
      </div>
      <div className="note-text">
        Từ <span>09:30 ngày 8.10.2022</span> đến{" "}
        <span>09:30 ngày 9.10.2022</span>
      </div> */}
    </SPriceModal>
  );
}

const SPriceModal = styled.div`
  position: relative;
  background: #411644;
  padding: 40px 20px;
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    color: white;
    margin-top: 0;
  }
  .price-container {
    padding-bottom: 10px;
    .table-header,
    .table-content {
      display: none;
    }

    display: flex;
    justify-content: center;
    table {
      display: block;
      th,
      td {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .note-text {
    color: white;
    text-align: center;
    font-size: 15px;
    line-height: 21px;
    span {
      color: #d29c44;
      text-transform: uppercase;
      font-weight: 500;
    }

    &.nowrap {
      white-space: nowrap;
    }
  }
`;
