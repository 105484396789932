export const IMAGE_HOST = "https://cdn.bvote.vn";

export const configId = (missId) => {
  let configId = missId;
  configId = Number(configId);
  if (configId < 10) {
    configId = "0" + configId;
  }

  return configId;
};

export const getAvatarUrl = (missId) => {
  return `${IMAGE_HOST}/mcnhihcm2023/avatar/${configId(missId)}.jpg`;
};

export const getDetailImage = (missId) => {
  return `${IMAGE_HOST}/mcnhihcm2023/avatar/${configId(missId)}.jpg`;
};

export const getBannerImage = () => {
  return `${IMAGE_HOST}/mcnhihcm2023/banner.jpg`;
};

export const getIntroImage = () => {
  return `${IMAGE_HOST}/mcnhihcm2023/intro.jpg`;
};
