import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { INTRO_HOME } from "../../../config/contentHtml";
import { INTRO1_EN } from "../../../config/contentHtml-en";
import styled from "styled-components";
import { getIntroImage } from "../../../libs";
class SectionAbout extends Component {
  state = {
    intro: "",
  };

  async componentDidMount() {
    try {
      const INTRO = this.props.lang === "en" ? INTRO1_EN : INTRO_HOME;

      const intro = INTRO;

      this.setState({ intro });
    } catch (error) {
      console.log("xxx Get intro : ", error);
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   return { lang: props.lang };
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      const INTRO = this.props.lang === "en" ? INTRO1_EN : INTRO_HOME;

      // const INTRO = this.props.lang === 'en' ? INTRO5_EN : INTRO5;

      const intro = INTRO;

      this.setState({ intro });
    }
  }

  render() {
    const { intro } = this.state;

    return (
      <SectionAboutStyle>
        <section className="section-about">
          <div className="bs-container">
            <div className="bs-row row-md-15">
              <div className="bs-col md-50-15">
                <div className="module module-about" data-aos="zoom-out-right">
                  <div className="module-header">
                    <h2 className="title">
                      <FormattedMessage id="nav.intro" />
                      {/* <span className='title-contain'>Giới thiệu</span> */}
                    </h2>
                  </div>
                  <div className="module-content">
                    <div
                      className="desc"
                      style={{ marginBottom: 5, fontSize: 16 }}
                    >
                      {ReactHtmlParser(intro)}
                    </div>
                    <Link className="about__link" to="/gioi-thieu">
                      <FormattedMessage id="index.continue" />
                      <span className="link-contain"> {">"}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bs-col md-50-15">
                <div className="about-video">
                  <div className="video-content">
                    <div className="video">
                      <img
                        src={getIntroImage()}
                        alt=""
                        class="about__img"
                        style={{ objectFit: "contain", height: "120%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SectionAboutStyle>
    );
  }
}

export default SectionAbout;

const SectionAboutStyle = styled.div`
  .section-about {
    .module-about {
      .module-header:after {
        display: none;
      }
      .module-header {
        .title:before {
          background-image: url("../images/Vector_5.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 193px;
          height: 33px;
          top: 41px;
          left: 0px;
          position: absolute;
        }
        .title:after {
          background-image: url("../images/Vector_6.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 50px;
          height: 41px;
          top: 8px;
          left: 180px;
        }
        span {
          position: relative;
          left: -14px;
          top: -5px;
          font-family: Baloo_2;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 150%;
          color: #153860;
          text-transform: none;
        }
      }
      .module-content {
        .desc {
          .reward-content {
            .reward-part {
              .item {
                p {
                  font-family: "Be Vietnam Pro";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #344050;
                }
              }
            }
          }
        }
        .about__link {
          margin-top: 40px;
          height: 48px;
          background: #e0a244;
          border-radius: 12px;
          span {
            font-family: "Be Vietnam Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 150%;
          }
        }
      }
    }
    .about-video {
      margin-left: 0;
      padding: 0;
      margin-top: 24px;
      max-width: 539px;
      background: none;
      box-shadow: none;
      .video-content {
        .video {
          .video-style1 {
            display: none;
          }
          .video-style2 {
            display: none;
          }
          iframe {
            left: 2.63%;
            right: 2.81%;
            top: 3.62%;
            bottom: 3.62%;

            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
              ),
              url(image.png);
            border-radius: 12px;
          }
        }
      }
    }
  }
`;
