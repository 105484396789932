/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import styled from "styled-components";
import { getBannerImage } from "../../libs";

export default function PageBanner() {
  return (
    <Styles>
      <img src={getBannerImage()} alt="" />
    </Styles>
  );
}

const Styles = styled.div`
  img {
    width: 100%;
  }
`;
