import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { ContestantsContext } from "../../../App";
import { ACTIVITY_SLIDE_SLICK } from "../../../config/slide";
import { WEB_API } from "../../../config/variables";
import BaseLayout from "../../Layouts/BaseLayout";
import RankCard from "../../Partials/RankCard";
import TopRankCard from "../../Partials/TopRankCard";
import { Styles } from "./Styles";
import { ItemRank } from "../../UI/ItemRank";
import { Item } from "../../UI/Item";
import { useParams } from "react-router-dom";
export default function Rank(props) {
  const title =
    props.lang === "en"
      ? "Rank - Poinsettia Festival English"
      : "Bảng xếp hạng - MC nhí Hồ Chí Minh 2023";

  const { contestants } = useContext(ContestantsContext);

  const rank = contestants || [];

  const [loading, setLoading] = useState(false);

  // const topRankCard = !loading ? (
  //   <React.Fragment>
  //     <TopRankCard
  //       order={3}
  //       miss={rank[0]}
  //       position={1}
  //       round={props.match.params.round}
  //     />
  //     <TopRankCard
  //       order={2}
  //       miss={rank[1]}
  //       position={2}
  //       round={props.match.params.round}
  //     />
  //     <TopRankCard
  //       order={4}
  //       miss={rank[2]}
  //       position={3}
  //       round={props.match.params.round}
  //     />
  //     <TopRankCard
  //       order={1}
  //       miss={rank[3]}
  //       position={4}
  //       round={props.match.params.round}
  //     />
  //     <TopRankCard
  //       order={5}
  //       miss={rank[4]}
  //       position={5}
  //       round={props.match.params.round}
  //     />
  //   </React.Fragment>
  // ) : (
  //   <React.Fragment>
  //     <TopRankCard order={3} position={1} round={props.match.params.round} />
  //     <TopRankCard order={2} position={2} round={props.match.params.round} />
  //     <TopRankCard order={4} position={3} round={props.match.params.round} />
  //     <TopRankCard order={1} position={4} round={props.match.params.round} />
  //     <TopRankCard order={5} position={5} round={props.match.params.round} />
  //   </React.Fragment>
  // );

  // const rankCard = !loading
  //   ? rank.map((el) => {
  //       return (
  //         <RankCard
  //           key={el.id}
  //           miss={el}
  //           loading={loading}
  //           round={props.match.params.round}
  //         />
  //       );
  //     })
  //   : listAll.map((el) => {
  //       return (
  //         <RankCard
  //           key={el.id}
  //           miss={el}
  //           loading={loading}
  //           round={props.match.params.round}
  //         />
  //       );
  //     });
  console.log("Tuấn Anh", rank);
  return (
    <BaseLayout
      title={title}
      description={title}
      setLang={props.setLang}
      isResult
    >
      <Styles>
        <main id="main" class="main-result">
          <section className="section-result">
            <div className="bs-container">
              <div className="title">
                <h4>Bảng xếp hạng bình chọn</h4>
                <h6>Tìm kiếm MC nhí Hồ Chí Minh 2023</h6>
              </div>
              <div className="top-rank">
                {!loading && (
                  <ItemRank className="top-2" rank={2} miss={rank[1]} />
                )}
                {!loading && (
                  <ItemRank className="top-1" rank={1} miss={rank[0]} />
                )}
                {!loading && (
                  <ItemRank className="top-3" rank={3} miss={rank[2]} />
                )}
              </div>
              <div className="rest-rank">
                {rank.map((item, index) => {
                  if (index + 1 >= 4) {
                    return <Item miss={item} rank={index + 1} />;
                  }
                })}
              </div>
            </div>
          </section>

          {/* <section className="section-result">
          <div className="bs-container">
            <div className="bs-row">
              <div className="bs-col">
                <div className="module module-result">
                  <div className="module-header">
                    <img src="/images/rank_header.gif" alt="" />
                  </div>
                  <div className="module-content">
                    <div className="part1">
                      <div className="result-top"> 
                        <div className="bs-row row-md-5 row-center">{topRankCard}</div>
                      </div>
                      <div className="result-title">
                        <img
                          height="197"
                          src="/images/result_title.gif"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="part2">
                      <div className="activity-advertisement">
                        <Slider
                          {...ACTIVITY_SLIDE_SLICK}
                          className="activity-slide"
                        >
                          <div className="slide-item">
                            <div className="item">
                              <a
                                href="https://pk28bdienbienphu.com/"
                                target="__blank"
                                className="link"
                              >
                                <img
                                  src="/images/bv-dbp.jpg"
                                  alt="pk28bdienbienphu"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="slide-item">
                            <div className="item">
                              <a
                                href="http://blockchain.bachkhoa-aptech.edu.vn"
                                target="__blank"
                                className="link"
                              >
                                <img
                                  src="/images/banner_bytesoft.jpg"
                                  alt="lap trinh ung dung blockchain"
                                />
                              </a>
                            </div>
                          </div>
                        </Slider>
                      </div>
                      <div className="result-list">
                        <div className="bs-row row-xs-10">{rankCard}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        </main>
      </Styles>
    </BaseLayout>
  );
}
