/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export default function Navbar({ setLang }) {
  const [active, setActive] = useState(false);

  return (
    <React.Fragment>
      <Styles id="header">
        <div className="header-nav">
          <div className="bs-container">
            <div className="nav-content clearfix">
              <div className="logo">
                <Link to="/">
                  <img
                    src="/images/logo-header.png"
                    alt=""
                    data-aos="fade-down"
                    data-aos-delay="300"
                  />
                </Link>
              </div>
              <div className="nav">
                <span className="show__menu" onClick={() => setActive(!active)}>
                  <i className="fas fa-bars" />
                </span>
                <div className={`menu ${active ? "active" : ""}`}>
                  <span
                    className="close__menu"
                    onClick={() => setActive(false)}
                  >
                    <i className="far fa-window-close" />
                  </span>
                  <ul className="menu-list clearfix">
                    <li className="menu-list__item">
                      <NavLink exact to="/" className="menu-list__link">
                        <FormattedMessage id="nav.home" />
                      </NavLink>
                    </li>

                    <li className="menu-list__item">
                      <NavLink to="/gioi-thieu" className="menu-list__link">
                        <FormattedMessage id="nav.intro" />
                      </NavLink>
                    </li>

                    {/* <li className="menu-list__item">
                      <a className="menu-list__link menu-rank">
                        Đợt dự thi
                        <div className="sub-popup">
                          <a
                            key="r1"
                            href="https://dot1-mcnhihochiminh.bvote.vn"
                          >
                            <div className="popup-item">Đợt 1</div>
                          </a>
                          <a
                            className="popup-item"
                            href="https://mcnhihochiminh.bvote.vn"
                          >
                            Đợt 2
                          </a>
                        </div>
                      </a>
                    </li> */}
                    <li className="menu-list__item">
                      <NavLink
                        to="/bang-xep-hang/1"
                        className="menu-list__link"
                      >
                        <FormattedMessage id="nav.rank" />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styles>
    </React.Fragment>
  );
}

const Styles = styled.div`
  &#header {
    position: unset;

    .header-nav {
      position: unset;
      background: #251d10;
      box-shadow: 4px 4px 24px #201608;
      color: white;
    }
  }
  /* &#header .header-nav .nav-content .nav .menu .menu-list .menu-list__item {
    :hover, ;
  } */
  &#header
    .header-nav
    .nav-content
    .nav
    .menu
    .menu-list
    .menu-list__item
    .menu-list__link {
    color: white;
    text-transform: unset;
    font-size: 20px;
    font-weight: 400;
    &.active {
      color: #cb8b28;
      :after {
        width: 100%;
        background: #cb8b28;
      }
    }
  }

  &#header
    .header-nav
    .nav-content
    .nav
    .menu
    .menu-list
    .menu-list__item
    .menu-list__link:only-child:hover {
    color: #cb8b28;
    :after {
      width: 100%;
      background: #cb8b28;
    }
  }

  &#header .header-nav .nav-content .nav .show__menu {
    color: white !important;
  }

  &#header .header-nav .nav-content .nav .menu {
    z-index: 99;
    background: #251d10;
  }

  .menu-rank {
    :hover {
      .sub-popup {
        display: block;
        z-index: 99;
      }
    }
  }

  .sub-popup {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    transform: translateY(100%);
    color: #1f2839;
    border-radius: 0 0 4px 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    z-index: 1;
    .popup-item {
      display: flex;
      align-items: center;
      padding: 8px 15px;
      font-size: 16px;

      :hover {
        color: #cb8b28;
      }
      ::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #cb8b28;
        margin-right: 15px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .sub-popup {
      background: #251d10;
      color: white;
      display: block;
    }
  }
`;
